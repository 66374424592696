<template>
  <div class="w-full h-full pt-5">
    <div class="flex flex-row justify-start items-center gap-4 px-2">
      <h1 class="text-xl font-extrabold">
        Payroll
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div class="px-3">
      <div style="height: 100%" class="mt-20" v-if="loadingData">
        <loader size="xxs" :loader-image="false" />
      </div>
      <card class="p-5 mt-6 flex flex-col" v-else>
        <div class="w-full text-jet">
          An overview of your payslips since date of employment
        </div>
        <div
          class="w-full border border-dashed border-romanSilver mt-4 mb-8"
          style="border-width: 0.5px"
        />

        <EmployeeProfile :employee="employee" />

        <div class="line" v-if="paySlipData.length > 0" />

        <div v-if="!loadingEarning && paySlipData.length > 0" class="w-full mt-5 flex flex-row justify-between rounded-lg px-3 py-4 bg-white earnings-container">
          <PaySlipEarning type="Monthly Earning"
            :earning="monthlyEarning"
            :earning-chart="monthlyEarningChart"
            :loading="loadingMonthly"
            :selected="earningMonthSelected"
            :options-box="monthlyOptions"
            @select="getSelected"
          />

          <PaySlipEarning type="Annual Earning"
            :earning="yearlyEarning"
            :earning-chart="yearlyEarningChart"
            :loading="loadingYearly"
            :selected="earningYearSelected"
            :options-box="yearlyOptions"
            @select="getSelected"
          />
        </div>

        <div class="line" />

        <p class="w-full font-bold text-darkPurple text-left text-base my-2">
          Payslip(s)
        </p>

        <div class="w-full h-auto mx-auto flex justify-between items-center">
          <div class="w-full flex flex-col justify-between">
            <p class="font-bold text-darkPurple text-base my-2">
              Filter By
            </p>
            <div class="flex">
              <radio-button
                :options="optionBox"
                col-span="col-span-6"
                class=" text-darkPurple"
                row-gap="gap-x-8"
                space-between="mr-2"
                v-model="paySlipFilter"
              />
            </div>
          </div>


          <div class="w-full flex justify-end items-end gap-2">

            <div class="h-10 flex justify-center items-center gap-3" v-if="paySlipFilter === 'range'">
              <div class="flex justify-center items-center gap-2">
                <datepicker
                  ref="startdatepicker"
                  style="width: 94px; position: relative; z-index:-9999; color: #321C3B;"
                  :value="startDate"
                  input-class="custom-input"
                  @selected="handleSelectedStartDate"

                />
                <div @click="openStartDatepicker" class="w-6 h-6 cursor-pointer relative ">
                  <icon icon-name="icon-calender" class-name="text-flame icon-calender" size="xs"/>
                </div>
              </div>

              <p class="relative  text-romanSilver text-sm"> --TO-- </p>

              <div class="flex justify-center items-center gap-2">
                <datepicker
                  ref="enddatepicker"
                  style="width: 94px; position: relative; z-index:-9999; color: #321C3B;"
                  :value="endDate"
                  input-class="custom-input"
                  @selected="handleSelectedEndDate"
                />
                <div  @click="openEndDatepicker" class="w-6 h-6 cursor-pointer relative ">
                  <icon icon-name="icon-calender" class-name="text-flame icon-calender" size="xs"/>
                </div>
              </div>
              </div>
            <div class="flex w-auto" v-else>
              <CSelect
                placeholder="--Month--"
                :options="optionMonth"
                variant="w-full h-10"
                class="mr-1"
                v-model="startMonth"
              />
              <CSelect
                placeholder=" --Select Year-- "
                :options="optionYears"
                variant="w-full h-10"
                class=""
                v-model="startYear"
              />
            </div>
            <Button
              class="text-white bg-dynamicBackBtn flex justify-center items-center w-20 h-10 relative "
              @click="filterPaySlip()"
            >
              Apply
            </Button>
          </div>
        </div>

        <div class="w-full flex my-6">
          <div class="flex flex-grow" />
        </div>

        <div style="height: 100%" class="mt-2" v-if="loadingTable">
          <loader size="xxs" :loader-image="false" />
        </div>

        <div v-else>
          <sTable
            :headers="paySlipHeader"
            :items="paySlipData"
            v-if="paySlipData.length > 0"
            class="w-full mt-4 mb-20"
            :has-number="false"
            aria-label="requestTable"
            :loading="false"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.year" class="underline pt-2 cursor-pointer">
                <span
                  class="w-auto"
                  @click="
                    $router.push({
                      name: 'ViewEssPayslip',
                      params: { id: item.data.id }
                    })
                  "
                >
                  {{
                    $DATEFORMAT(
                      new Date(
                        item.data.year +
                          "-" +
                          "0" +
                          item.data.month +
                          "-" +
                          1
                      ),
                      "LLLL yyyy"
                    )
                  }}
                  Payslip
                </span>
              </div>
              <div v-else-if="item.payrun">
                {{
                  item.data.payrun.payFrequency
                    ? handlePayFrequency(item.data.payrun.payFrequency)
                    : "-"
                }}
              </div>
              <div v-else-if="item.documentId">
                {{
                  `${$DATEFORMAT(
                    new Date(item.data.payrun.month + "-" + 1),
                    "LLLL"
                  )} ${item.data.payrun.payStartDay} - ${
                    item.data.payrun.payEndDay
                  }`
                }}
              </div>
              <div class="flex gap-7 justify-between" v-else-if="item.data.id">
                <div
                  @click="viewPaySlip(item.data.id)"
                  class="flex justify-center items-center text-blueCrayola py-1 more h-8 cursor-pointer"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                  <p class=" underline text-sm ml-2">View</p>
                </div>

                <div v-if="hasDownloadStarted && downloadId === item.data.id">
                  <loader size="xxxs" :loader-image="false" loading-bar-size="width:80px" />
                </div>
                <div
                  @click="invokeDownloadPaySlip(item.data.id)"
                  class="flex justify-center items-center text-aztecJade py-1 more h-8 cursor-pointer"
                  v-feature-request
                  v-else

                >
                  <icon
                    icon-name="download-icon"
                    class-name="text-aztecJade"
                    size="xs"
                  />
                  <p class=" underline text-sm ml-2">Download</p>
                </div>

                <!-- <div
                  @click="invokePrintSlip(item.data.id)"
                  class="flex justify-center items-center text-razzmataz py-1 more h-8 cursor-pointer"
                  v-feature-request
                >
                  <icon
                    icon-name="print-icon"
                    class-name="text-razzmataz"
                    size="xs"
                  />
                  <p class=" underline text-sm ml-2">Print</p>
                </div> -->
              </div>
            </template>
          </sTable>

          <div v-if="paySlipData.length === 0" class="mt-4">
            <div class="flex font-bold text-desire text-base justify-center">
              You will find all payslips for this employee here, from the time
              you pay the employee using Cloudenly.
            </div>
          </div>
        </div>
      </card>
    </div>
    <PrintDownloadPaySlip ref="print" @hasDownloadStarted="hasDownloadStarted = $event" v-if="paySlipData.length >
    0"/>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { format } from "date-fns";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import RadioButton from "@/components/RadioButton";

export default {
  components: {
    Datepicker,
    Breadcrumb,
    Card,
    EmployeeProfile: () => import("@/components/EmployeeProfile"),
    PaySlipEarning: () => import("@/components/PaySlipEarning"),
    PrintDownloadPaySlip: () => import("@/components/PrintDownloadPaySlip"),
    CSelect,
    Button,
    STable,
    RadioButton,
  },
  data() {
    return {
      loadingData: true,
      loadingTable: true,
      hasDownloadStarted: false,
      downloadId: '',
      breadcrumbs: [
        {
          disabled: false,
          text: "Benefits",
          href: "Benefits",
          id: "Benefits"
        },
        {
          disabled: false,
          text: "Payslips",
          id: "Payslips"
        }
      ],
      paySlipHeader: [
        { title: "PaySlip", value: "year", width: 25 },
        { title: "Pay Frequency", value: "payrun",  width: 25 },
        { title: "Pay Period", value: "documentId", width: 25 },
        { title: "Actions", value: "id", width: 22 }
      ],
      paySlipData: [],
      employee: [],
      optionYears: [],
      optionBox: [
        { name: "Specific Month", radioName: "month", value: "specific" },
        { name: "Period", radioName: "month", value: "range" }
      ],
      paySlipFilter: "range",
      optionMonth: [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sept" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" }
      ],
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",

      selectedStartDate: null,
      selectedEndDate: null,

      loadingEarning: true,
      loadingMonthly: true,
      loadingYearly: true,
      monthlyTimerId: null,
      yearlyTimerId: null,
      earningYearSelected: 0,
      earningMonthSelected: "",
      earningYearOptions: [],
      earningMonthOptions: [],
      yearlyEarnings: [],
      monthlyEarnings: [],
      monthlyEarningChart: {},
      yearlyEarningChart: {}
    };
  },
  computed: {
     startDate() {
      return this.selectedStartDate;
    },

    endDate() {
      return this.selectedEndDate;
    },

    monthlyOptions(){
      return this.earningMonthOptions;
    },
    yearlyOptions() {
      return this.earningYearOptions;
    },
    monthlyEarning(){
      return this.monthlyEarnings.find(month => month.payStartDate === this.earningMonthSelected)
    },
    yearlyEarning() {
      return this.yearlyEarnings.find(year => year.year === this.earningYearSelected)
    },
  },
  watch: {
    monthlyEarning(newVal) {
      this.monthlyChart(newVal);
      this.monthlyTimerId = null;
    },
    hasDownloadStarted(newVal){
      if(!newVal){
        this.downloadId = ''
      }
    },

    yearlyEarning(newVal) {
      this.yearlyChart(newVal);
      this.yearlyTimerId = null
    }
  },
  methods: {
    openStartDatepicker() {
      this.$refs.startdatepicker.$el.querySelector('input').click();
    },
    openEndDatepicker() {
      this.$refs.enddatepicker.$el.querySelector('input').click();
    },

    handleSelectedStartDate(date) {
      this.selectedStartDate = date;
    },

    handleSelectedEndDate(date) {
      this.selectedEndDate = date;
    },

    async viewPaySlip(payslipId) {
      this.$router.push({ name: "ViewEssPayslip", params: { id: payslipId } });
    },

    invokePrintSlip(slipId) {
      this.$refs.print.fetchAndPrintData(slipId);
    },

    async invokeDownloadPaySlip(slipId) {
      this.downloadId = slipId
      this.hasDownloadStarted = true
      await this.$refs.print.downloadData(slipId);
    },

    getSelected(selected, type) {
      if(type === "Monthly Earning"){
        this.loadingMonthly = true;
        this.earningMonthSelected = selected
      }
      if(type === "Annual Earning"){
        this.loadingYearly = true;
        this.earningYearSelected = +selected
      }
    },

    monthlyChart(earning) {
      const monthly =  {
        labels: [`Net Pay Amount`,`Additions`, `Deductions`,`Pension`, `PAYE`],
        datasets: [{
          backgroundColor: ['#F15A29', '#13B56A', '#FF0000', '#2176FF', '#490166'],
          offset: 30,
          data: [Math.round(earning.netPay) || 0,
            Math.round(earning.additions) || 0,
            Math.round(earning.deductions) || 0,
            Math.round(earning.totalPension) || 0, Math.round(earning.paye) || 0
          ]
        }]
      }
      this.monthlyEarningChart = monthly;
      if(this.monthlyTimerId){
        clearTimeout(this.monthlyTimerId)
      }
      this.monthlyTimerId = setTimeout(() => {
        this.loadingMonthly = false
      }, 0)
    },

    yearlyChart(earning) {
      const yearly =  {
        labels: [`Total Net Pay Amount`,`Total Additions`,`Total Deductions`,`Total Pension`,`Total PAYE`],
        datasets: [{
          backgroundColor: ['#F15A29', '#13B56A', '#FF0000', '#2176FF', '#490166'],
          offset: 30,
          data: [ earning.netPayPercentage || 0,
            earning.additionsPercentage || 0,
            earning.deductionsPercentage || 0,
            earning.totalPensionPercentage || 0,
            earning.payePercentage || 0
          ]
        }]
      }
      this.yearlyEarningChart = yearly;
      if(this.yearlyTimerId){
        clearTimeout(this.yearlyTimerId)
      }
      this.yearlyTimerId = setTimeout(() => {
        this.loadingYearly = false
      }, 0)
    },

    getMonthlyPaySlipEarnings() {
      this.$_getPaySlipMonthlyEarnings(this.$AuthUser.id).then(res => {
        this.earningMonthSelected = res.data.payslipsData[0]?.payStartDate;
        this.monthlyEarnings = res.data.payslipsData;

        for (let i = 0; i < res.data.payslipsData.length; i++) {
          const setObjMonth = { id: res.data.payslipsData[i].payStartDate,
                                name: res.data.payslipsData[i].payStartDate
                              };
          this.earningMonthOptions.push(setObjMonth);
        };
      });
    },

    getYearlyPaySlipEarnings() {
      this.$_getPaySlipYearlyEarnings(this.$AuthUser.id).then(res => {
        this.earningYearSelected = res.data.data[0]?.year
        this.yearlyEarnings = res.data.data

        for (let i = 0; i < this.yearlyEarnings.length; i++) {
          const {year} = this.yearlyEarnings[i]
          const setObjYear = { id: year, name: `${year} (YTD)` };
          this.earningYearOptions.push(setObjYear);
        };
      });
    },

    extractMonthAndYear(dateString) {
      const date = new Date(dateString);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return { month, year };
    },

    filterPaySlip() {
      this.loadingTable = true;
      let params;

      const userId = this.$AuthUser.id;
      const startdate = new Date(this.startDate);
      const enddate = new Date(this.endDate);
      const formattedStartDate = format(startdate, 'MM/dd/yyyy');
      const formattedEndDate = format(enddate, 'MM/dd/yyyy');
      const start = this.extractMonthAndYear(formattedStartDate);
      const end = this.extractMonthAndYear(formattedEndDate);

      if (this.paySlipFilter === "range") {
        params = `fromYear=${start.year}&fromMonth=${start.month}&toYear=${end.year}&toMonth=${end.month}`;
      } else {
        params = `fromYear=${this.startYear}&fromMonth=${this.startMonth}&toYear=${this.startYear}&toMonth=${this.startMonth}`;
      }

      this.$_filterPaySlips(userId, params).then(response => {
        this.paySlipData = response.data.payslips;
        this.loadingTable = false;
      });
    },

    getDetails() {
      const userId = this.$AuthUser.id;
      let i;

      this.$_getOneEmployeeAll({ id: userId }).then(result => {
        this.employee = result.data;

        const employmentTime = new Date(result.data.employmentDate);
        employmentTime.setHours(employmentTime.getHours() + 8);

        const employmentYear = employmentTime.getFullYear();
        const employmentMonth = employmentTime.getMonth() + 1;
        const startDay = employmentTime.getDate();

        this.startMonth = employmentMonth;
        this.startYear = employmentYear;
        this.selectedStartDate = `${this.startMonth}/${startDay}/${this.startYear}`;

        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 8);

        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();

        this.endMonth = currentMonth;
        this.endYear = currentYear;
        this.selectedEndDate = `${this.endMonth}/${currentDay}/${this.endYear}`;

        for (i = employmentYear; i <= currentYear; i++) {
          const setObjYear = { id: i, name: i };
          this.optionYears.push(setObjYear);
        }

        const params = `fromYear=${employmentYear}&fromMonth=${employmentMonth}&toYear=${currentYear}&toMonth=${currentMonth}`;

        this.$_filterPaySlips(userId, params).then(response => {
          this.paySlipData = response.data.payslips;
          this.loadingTable = false;
        });

        this.loadingData = false;
      });
    },
  },
  mounted() {
    this.getMonthlyPaySlipEarnings();
    this.getYearlyPaySlipEarnings();
    this.loadingEarning = false;
    this.getDetails();
  }
};
</script>

<style scoped>
.earnings-container {
  box-shadow: 0px 4px 16px 0px #2222221F;
  position: relative;
}

.line{
  border: 0.5px solid #d6dbe4;
  width: 80%;
  height: 0;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0;
}

.icon-calender {
 width: 100% !important;
 height: 100% !important;
}

::v-deep .vdp-datepicker__calendar{
  transform: translateX(-50%);
}

.vdp-datepicker__input{
  border: none !important;
  outline: none !important;
}
</style>